/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Typography from "root/components/Typography";
import { useTranslation } from "react-i18next";
import legalStyles from "../../sections/LegalHero/index.module.css";
import LegalDocument from "../legal-document";

function PrivacyPolicy({ launcher }) {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage("en");
  }, []);

  return (
    <LegalDocument
      t={t}
      title={t("pages.privacy.label")}
      bodyTitle={t("pages.privacy.title")}
      currentPage="privacy"
      launcher={launcher}
    >
      <Typography variant="h2" color="yellow" weight="medium">
        Sioslife – For Younger Spirits (Sioslife S.A.)
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          This Privacy and Personal Data Protection Policy generally applies to
          the data management and data protection of the personal data provided
          by the User who accesses any Sioslife platform, namely those collected
          through forms, documents or other means intended to allow contact with
          Sioslife S.A., legal person 513107517, registered at the Commercial
          Registry Office of Braga, under the same number, with registered
          office at Rua Eng. Nuno Álvares Pereira, nº 33, 4710 915 Braga, and
          with a share capital of 61,745.71 EUR, hereafter &quot;Sioslife&quot;,
          also applying to the rights granted to the User in relation to this
          data, under the terms of the applicable legislation.
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          This Policy is general in nature, so the information provided in it
          can be complemented or removed, in whole or in part, by other
          policies, contracts, notices or information of a more specific nature
          that may have been or will be made available in the context of certain
          types of processing of personal data, or of a commercial relationship
          between Sioslife and the User.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        1. Introduction
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          Thank you for using Sioslife products!
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          At Sioslife, we want to give you the best possible experience using
          our services. It is precisely for this purpose that we need to
          understand your habits of using our systems, making it possible to
          personalise and progressively improve your experience with us. To this
          purpose, we aim to explain, in a transparent manner, why and how we
          collect, share, store and use your personal data, while at the same
          time providing you with control and choice over when and how you
          choose to share your personal data.
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          In this Privacy Policy, or simply &quot;Policy&quot;, we will explain
          in detail all the information to be taken into account when managing
          your personal data.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        2. About this document
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          This &quot;Policy&quot; sets out the essential details regarding your
          personal data relationship with Sioslife. The Policy applies to all
          Sioslife services and any services associated with their use
          (&quot;Sioslife Services&quot;). The conditions that guide your use of
          the Sioslife Services are set out in our Terms and Conditions of Use
          document (the &quot;Terms and Conditions of Use&quot;).
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          Whenever additional new services or products are developed, offered or
          made available, and in cases where their introduction results in any
          material change in the way we collect or process personal data, we
          undertake to provide all new additional information, conditions and/or
          policies. Where this is not required, and unless otherwise stated, new
          services or products will be subject to this Policy.
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          Therefore, the objectives of this Policy are:
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <ol>
          <li>
            <Typography color="black">
              1. Ensure your understanding of the personal data we collect, the
              reasons for this collecting and use, and with whom we share it;
            </Typography>
          </li>
          <li>
            <Typography color="black">
              2. Explain how we use the personal data shared with us, in order
              to ensure a better user experience;
            </Typography>
          </li>
          <li>
            <Typography color="black">
              3. Explain your rights and choices concerning the personal data we
              collect and process and how we aim to protect your privacy.
            </Typography>
          </li>
        </ol>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          We hope that this document will be able to clarify all questions
          related to the use of personal data in Sioslife services. For more
          information, or simply to clarify other questions, please find out how
          you can contact us in Section 13 &quot;How can you contact us?&quot;.
          Alternatively, if you do not agree with this Policy, please remember
          that you can choose not to use the Sioslife Services.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        3. Choice and control options
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          The General Data Protection Regulation or &quot;GDPR&quot; confers
          certain rights on natural persons with regard to their personal data.
          The rights conferred on natural persons, without prejudice to the
          limitations provided for in the applicable legislation, are the
          following:
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <ul>
          <li>
            <Typography color="black">
              Right of access - right to be informed and request access to your
              personal data that we process;
            </Typography>
          </li>
          <li>
            <Typography color="black">
              Right of rectification - right to request that we amend or update
              your personal data when it is inaccurate or incomplete;
            </Typography>
          </li>
          <li>
            <Typography color="black">
              Right to erasure - right to request that we delete your personal
              data from our services and systems;
            </Typography>
          </li>
          <li>
            <Typography color="black">
              Right to restriction - right to request that we temporarily or
              permanently cease processing all or some of your personal data;
            </Typography>
          </li>
          <li>
            <Typography color="black">
              Right to object - right, at any time, to object to the processing
              of your personal data on grounds relating to your particular
              situation; or, right to object to the processing of your personal
              data for direct marketing purposes;
            </Typography>
          </li>
          <li>
            <Typography color="black">
              Right to data portability - right to request a copy of your
              personal data in electronic format and right to transmit it for
              the purpose of use in the service of third parties;
            </Typography>
          </li>
          <li>
            <Typography color="black">
              Right not to be subject to automated decision-making - the right
              not to be subject to a decision based solely on automated
              decision-making, including profiling, if the decision produces a
              legal effect or an equally significant effect on you;
            </Typography>
          </li>
        </ul>
        <div className={legalStyles.description}>
          <Typography color="black">
            Please contact our Personal Data Protection Officer via the
            &quot;Contact Us&quot; form on our website if you have any questions
            about your privacy, your rights or the exercise of your rights. If
            you have any questions about our method of processing your personal
            data, we would like you to continue working with us to clarify them.
            However, you can also contact and exercise your right to lodge a
            complaint with your local Data Protection Authority.
          </Typography>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        4. What personal data do we collect?
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          In the tables below, we list the categories of personal data that we
          collect and use, in relation to users, and how we collect them:
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          The table below describes the personal data collected when you first
          connect to the Sioslife Services:
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <table>
          <tr>
            <td>
              <Typography color="black" variant="smallBody" weight="medium">
                Personal data categories
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody" weight="medium">
                Category description
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                User Data
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                This is the personal data provided by the user or collected by
                Sioslife to enable the user to use the Sioslife Services. Such
                personal data may include your username, email address,
                telephone number, date of birth, gender, address and country.
              </Typography>
              <br />
              <Typography color="black" variant="smallBody">
                Some of the personal data requested is necessary to create your
                account. You also have the option to provide us with additional
                personal data to make your account more personalised.
              </Typography>
            </td>
          </tr>
        </table>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          The table that follows highlights the personal data collected
          according to user category:
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <table>
          <tr>
            <td>
              <Typography color="black" variant="smallBody" weight="medium">
                Personal data categories
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody" weight="medium">
                Category description
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                &quot;Senior&quot; Usage Data
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                This is the personal data collected about you when you access
                and/or use the Sioslife Services, namely:
              </Typography>
              <ul style={{ marginTop: "12px" }}>
                <li>
                  <Typography color="black" variant="smallBody">
                    Information about your subscription to Sioslife Services;
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Information about your interactions with the Sioslife
                    Services, such as preferences, applications used, content
                    personalized for you, content shares made or received;
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Conclusions extracted about your interests and preferences
                    based on your use of the Sioslife Services;
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    User Content (as defined in the [Terms and Conditions of
                    Use] (TC)) that you post on the Sioslife Services, such as
                    photos or videos. Please note that we will only access your
                    camera or the photos on your device if you grant us
                    permission to do so, and that we will only have access to
                    images that you specifically decide to share with us, as
                    well as to metadata related to such images, such as the file
                    type and the size of the image. Under no circumstances will
                    we access any type of data that has not been shared through
                    the Sioslife Services;
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Some technical personal data, which may include:
                  </Typography>
                  <ul>
                    <li>
                      <Typography color="black" variant="smallBody">
                        URL information;
                      </Typography>
                    </li>
                    <li>
                      <Typography color="black" variant="smallBody">
                        online identifiers, including cookie data and IP
                        addresses;
                      </Typography>
                    </li>
                    <li>
                      <Typography color="black" variant="smallBody">
                        information about the types of devices used, such as
                        unique device IDs, network connection type (e.g. Wi-Fi,
                        3G, LTE, Bluetooth), provider, network and device
                        performance, browser type, language, digital rights
                        management information, operating system and Sioslife
                        app version;
                      </Typography>
                    </li>
                    <li>
                      <Typography color="black" variant="smallBody">
                        attributes of devices on your Wi-Fi network available to
                        connect to the Sioslife Services (such as speakers);
                      </Typography>
                    </li>
                    <li>
                      <Typography color="black" variant="smallBody">
                        your non-precise location, which may be derived or
                        inferred from certain technical data (e.g. your IP
                        address, your device&apos;s language setting or payment
                        currency), to fulfil the geographic requirements of our
                        licensing agreements and provide personalized content
                        and advertising;
                      </Typography>
                    </li>
                    <li>
                      <Typography color="black" variant="smallBody">
                        mobile sensor data generated by movement or orientation
                        (e.g. accelerometer or gyroscope) necessary to provide
                        you with specific features of the Sioslife Services;
                      </Typography>
                    </li>
                  </ul>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                &quot;Caregiver&quot; Usage Data
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                This is the personal data collected about you when you access
                and/or use the Sioslife Services, namely:
              </Typography>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Information about your subscription to Sioslife Services;
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Conclusions extracted about your interests and preferences
                    based on your use of the Sioslife Services;
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    User Content (as defined in the [Terms and Conditions of
                    Use] (TC)) that you post on the Sioslife Services, such as
                    photos or videos. Please note that we will only access your
                    camera or the photos on your device if you grant us
                    permission to do so, and that we will only have access to
                    images that you specifically decide to share with us, as
                    well as to metadata related to such images, such as the file
                    type and the size of the image. Under no circumstances will
                    we access any type of data that has not been shared through
                    the Sioslife Services;
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Some technical personal data, which may include:
                  </Typography>
                  <ul>
                    <li>
                      <Typography color="black" variant="smallBody">
                        URL information;
                      </Typography>
                    </li>
                    <li>
                      <Typography color="black" variant="smallBody">
                        online identifiers, including cookie data and IP
                        addresses;
                      </Typography>
                    </li>
                    <li>
                      <Typography color="black" variant="smallBody">
                        nformation about the types of devices used, such as
                        unique device IDs, type of network connection (e.g.
                        Wi-Fi, 3G, LTE, Bluetooth), provider, network and device
                        performance, browser type, language, digital rights
                        management information, operating system and version of
                        the Sioslife app;
                      </Typography>
                    </li>
                    <li>
                      <Typography color="black" variant="smallBody">
                        attributes of devices on your Wi-Fi network available to
                        connect to the Sioslife Services (such as speakers);
                      </Typography>
                    </li>
                    <li>
                      <Typography color="black" variant="smallBody">
                        your non-precise location, which may be derived or
                        inferred from certain technical data (e.g. your IP
                        address, your device&apos;s language setting or payment
                        currency), to fulfil the geographic requirements of our
                        licensing agreements and to provide personalised content
                        and advertising;
                      </Typography>
                    </li>
                  </ul>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Plan Verification Data
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                We may use a third party mapping application (such as Google
                Maps) and/or the location service of your device to help you
                verify your address. This data is collected for the sole purpose
                of verifying eligibility to use our scheme and is not used for
                advertising or any other purpose.
              </Typography>
            </td>
          </tr>
        </table>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          The table below describes the personal data that you choose to provide
          to us and that allow us to provide features / functionalities of our
          services, regardless of the type of user:
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <table>
          <tr>
            <td>
              <Typography color="black" variant="smallBody" weight="medium">
                Personal data categories
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody" weight="medium">
                Category description
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Voice Data
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                Sioslife collects the user&apos;s voice data after obtaining
                authorization from the user for the purpose of providing
                additional features and functionalities, such as interaction
                with the Sioslife Services via voice.
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Video Data
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                Sioslife collects the user&apos;s video data after obtaining
                authorization from the user for the purpose of providing
                additional features and functionalities, such as interaction
                with the Sioslife Services via voice.
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Payment & Purchase Details
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                Sioslife may collect certain personal data in the event that you
                sign up for a Trial Period or any of our Paid Subscriptions (as
                defined in the Terms and Conditions of Use), or make other
                purchases through the Sioslife Services. The exact personal data
                collected varies depending on the payment method (e.g. directly
                through your mobile phone provider or via invoice), but includes
                information such as:
              </Typography>
              <ul style={{ marginTop: "12px" }}>
                <li>
                  <Typography color="black" variant="smallBody">
                    Name;
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Date of birth;
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Credit or debit card type, expiry date and certain digits of
                    the user&apos;s card number;
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Postcode;
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Mobile phone number; and
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Details of the user&apos;s purchase and payment history.
                  </Typography>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Data relating to Competitions, Surveys, and Drawings
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                Every time you fill in a form, answer a survey or questionnaire,
                or take part in a competition, we will collect the personal data
                you provide.
              </Typography>
            </td>
          </tr>
        </table>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          The table below describes the personal data collected from third
          parties. We collect users&apos; personal data from various third
          parties. These sources (third parties) vary over time and include:
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <table>
          <tr>
            <td>
              <Typography color="black" variant="smallBody" weight="medium">
                Source categories (third parties)
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody" weight="medium">
                Category description
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Authentication partners
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                In case you register or access the Sioslife Services using third
                party credentials (e.g. Facebook or Google), we will import the
                information from the third party for the purpose of creating
                your account with us.
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Technical service partners
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                We work with technical service partners who provide us with
                certain data, such as IP address mapping to non-precise location
                data (e.g. city, state), that allows us to provide the Sioslife
                Services, content and features.
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Payment partners
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                If you choose to pay for a service or feature by invoice, we may
                receive data from our payment partners for the purposes of
                sending you invoices, processing your payment and providing
                products.
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Advertisers and other ad partners
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                We may obtain certain user data such as cookie ID, mobile device
                ID or email address, as well as insights into the interests and
                preferences of certain advertisers and advertising partners,
                allowing us to serve more relevant adverts and measure their
                effectiveness.
              </Typography>
            </td>
          </tr>
        </table>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        5. For what purposes do we use your personal data?
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          When you use or interact with the Sioslife Services, we use a variety
          of technologies to process the personal data we collect from you for a
          number of reasons. The table below sets out the reasons why we process
          your personal data, the legal grounds on which we rely in order to
          process such personal data and the categories of personal data
          (identified in the previous Section) used for such purposes:
        </Typography>
      </div>
      <div className={legalStyles.description} style={{ overflowX: "auto" }}>
        <table>
          <tr>
            <td>
              <Typography color="black" variant="smallBody" weight="medium">
                Description of the reasons why we process your personal data
                (&quot;purpose of processing&quot;)
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody" weight="medium">
                Legal basis for the purpose of processing
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody" weight="medium">
                Categories of personal data used by Sioslife for the purpose of
                processing
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Providing and customizing Sioslife Services
              </Typography>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Execution of a Contract
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Legitimate Interest
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Consent
                  </Typography>
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    User Data
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Usage Data
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Payment & Purchase
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Plan Verification
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Voice Data
                  </Typography>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Understanding, diagnosing, troubleshooting and correcting
                Sioslife Services problems
              </Typography>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Execution of a Contract
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Legitimate Interest
                  </Typography>
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    User Data
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Usage Data
                  </Typography>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Evaluate and develop new functionalities, technologies and
                improvements for Sioslife Services
              </Typography>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Legitimate Interest
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Consent
                  </Typography>
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    User Data
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Usage Data
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Voice Data
                  </Typography>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Marketing, promotion, and advertising purposes
              </Typography>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Legitimate Interest
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Consent
                  </Typography>
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    User Data
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Usage Data
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Voice Data
                  </Typography>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Meet legal obligations and law enforcement requests
              </Typography>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Compliance with Legal Obligations
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Legitimate Interest
                  </Typography>
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    User Data
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Usage Data
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Payment and Purchase Data
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Voice Data
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Contest, Survey, and Giveaway Data
                  </Typography>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Comply with contractual obligations to third parties (e.g.
                license agreements), as well as take appropriate action in
                relation to reports of intellectual property infringement and
                inappropriate content.
              </Typography>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Legitimate Interest
                  </Typography>
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    User Data
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Usage Data
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Payment and Purchase Data
                  </Typography>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Declare, exert or defend a right in legal proceedings
              </Typography>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Legitimate Interest
                  </Typography>
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    User Data
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Usage Data
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Payment and Purchase Data
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Voice Data
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Contest, Survey, and Giveaway Data
                  </Typography>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Carry out business planning, reporting and forecasting
              </Typography>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Legitimate Interest
                  </Typography>
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    User Data
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Usage Data
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Payment and Purchase Data
                  </Typography>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Processing your payment
              </Typography>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Compliance with Legal Obligations
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Performance of a Contract
                  </Typography>
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    User Data
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Payment and Purchase Data
                  </Typography>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Detect fraud, including fraudulent payments and fraudulent use
                of Sioslife Services
              </Typography>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Compliance with Legal Obligations
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Performance of a Contract
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Legitimate Interest
                  </Typography>
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    User Data
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Usage Data
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Payment and Purchase Data
                  </Typography>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Conducting surveys, competitions, polls, and Giveaways
              </Typography>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Consent
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Performance of a Contract
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Legitimate Interest
                  </Typography>
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    User Data
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Usage Data
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Contest, Survey, and Giveaway Data
                  </Typography>
                </li>
              </ul>
            </td>
          </tr>
        </table>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        6. How do we share your personal data?
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black" weight="medium">
          Information available to users of the Sioslife platforms
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          We have established categories of recipients of personal data
          collected or generated through your use of the Sioslife Services.
          <br />
          The personal data described below will always be available on the
          Sioslife Services: your name and/or username, profile picture, circle
          of contacts created by you and who registered you on the Sioslife
          Services, your public information.
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black" weight="medium">
          Personal data that you may choose to share
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          The personal data listed below will be shared only with the categories
          of recipients indicated in the table below if:
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <ul>
          <li>
            <Typography color="black">
              You choose to use a specific feature of the Sioslife Services
              where the sharing of specific personal data is necessary for the
              proper use of the features of the Sioslife Services;
            </Typography>
          </li>
          <li>
            <Typography color="black">
              You grant consent to the sharing of your personal data, for
              example by selecting the appropriate setting of the Sioslife
              Services or by authorising Sioslife through the consent mechanism
              provided by Sioslife (typically the declaration of consent upon
              first use of our services);
            </Typography>
          </li>
        </ul>
      </div>
      <div className={legalStyles.description}>
        <table>
          <tr>
            <td>
              <Typography color="black" variant="smallBody" weight="medium">
                Recipient Categories
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody" weight="medium">
                Justification for the Sharing of Personal Data
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Third-party apps and user-connected devices
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                In case you connect your Sioslife account to a third party
                application and/or device(s), such as social media, audio,
                television or automotive platforms and services, Sioslife shares
                technical data with the operator of the concerned application
                and/or third party device to connect your Sioslife account
                and/or facilitate the playback of the Sioslife Services. For
                some third parties that may request or require us to share your
                information with them, your authorization will be requested
                before we provide any information.
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Data sharing between &quot;senior&quot; and
                &quot;caregiver&quot;
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                In case one of the users wants to make contact in real time or
                share content such as written messages, voice, videos or other
                multimedia elements. If one of the caregivers wants to
                personalize or help with remote settings of the device used by
                the senior.
              </Typography>
            </td>
          </tr>
        </table>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black" weight="medium">
          Information that we can share
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <table>
          <tr>
            <td>
              <Typography color="black" variant="smallBody" weight="medium">
                Recipient Categories
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody" weight="medium">
                Justification for the Sharing of Personal Data
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Service Providers
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                We co-operate with service providers who work on our behalf and
                who may need to access certain personal data of users for the
                purpose of providing their services. These companies include
                those contracted to provide customer support services, to
                operate the technical infrastructure necessary to make the
                Sioslife Services available, to help protect and secure our
                systems and services, and to assist in the marketing of Sioslife
                products and services.
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Payment processors
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                We share your personal data with our payment processors as
                necessary to enable them to process your payments, as well as
                for anti-fraud purposes.
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Advertising partners
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                We work together with advertising partners, which allows us to
                personalise the advertising content you may receive on the
                Sioslife Services. These partners help us to deliver the most
                relevant advertisements and promotional messages to you, from
                interest-based advertising (online behavioural advertising) to
                contextual advertising and generic advertising on the Sioslife
                Services. Together with our advertising partners, we may process
                certain personal data to help Sioslife understand your interests
                or preferences and provide you with more relevant adverts.
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Academic Researchers
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                We share your personal data for activities such as statistical
                analysis and academic study, but only under pseudonymisation.
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Other companies in the Sioslife Group
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                We share your personal data with other companies in the Sioslife
                Group in order to carry out our daily business operations and to
                enable us to maintain and provide you with the Sioslife
                Services.
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Law enforcement and personal data protection authorities
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                We share your personal data when we believe in good faith that
                it is necessary to do so for the purposes of complying with a
                legal obligation imposed under applicable law, or to respond to
                a valid legal process, such as a search warrant, court order or
                subpoena. We also share your personal data when we believe in
                good faith that it is necessary to do so for the purposes of our
                own legitimate interest or the legitimate interest of a third
                party relating to national security, law enforcement,
                litigation, criminal investigation, protecting the safety of any
                person or to prevent imminent death or physical harm, provided
                that we consider that such interest is not overridden by your
                interests or fundamental rights and freedoms which require the
                protection of your personal data.
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Buyers of Sioslife&apos;s commercial activity
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                We share your personal data in cases of sale or negotiation of
                the sale of our business to a buyer or potential buyer. In this
                situation, Sioslife continues to guarantee the confidentiality
                of your personal data and will notify you in advance of the
                transfer of your personal data to the buyer or that they are
                subject to a different privacy policy.
              </Typography>
            </td>
          </tr>
        </table>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          Sioslife ensures that the possible sharing of data will only be made
          to entities that disclose identical privacy policy.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        7. Storage and deletion of personal data
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          We keep your personal data only for as long as it is necessary for the
          purpose of providing you with Sioslife Services, as well as for
          legitimate and essential business purposes, such as maintaining the
          performance of Sioslife Services, making business decisions based on
          data about new features and offers, complying with our legal
          obligations and resolving disputes.
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          If requested, we will delete or anonymise your personal data so that
          you can no longer be identified, unless we are legally authorised or
          required to keep certain personal data, including in the following
          situations:
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <ul>
          <li>
            <Typography color="black">
              Where there is an unresolved issue relating to your account, such
              as an outstanding claim or an ongoing complaint or dispute, we
              will retain the necessary personal data until the issue is
              resolved;
            </Typography>
          </li>
          <li>
            <Typography color="black">
              Where it is necessary to retain personal data for the purposes of
              our legal, tax, audit and accounting obligations, we will retain
              the necessary personal data for the period required by applicable
              law;
            </Typography>
          </li>
          <li>
            <Typography color="black">
              Where necessary for our legitimate business interests, such as
              preventing fraud or maintaining the security of our users.
            </Typography>
          </li>
        </ul>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        8. Transmission of personal data to other countries
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          Sioslife shares your personal data globally with Sioslife Group
          companies for the purpose of carrying out the activities specified in
          this Policy. Sioslife may also subcontract the processing, or share
          your personal data with third parties located in other countries. Your
          personal data may therefore be subject to different privacy laws than
          those applicable in your country.
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          Personal data collected in the European Union and Switzerland may, for
          example, be transmitted to and processed by third parties located in a
          country outside the European Union and Switzerland. In such cases,
          Sioslife shall ensure that the transmission of your personal data is
          carried out in accordance with applicable privacy laws and, in
          particular, that all appropriate contractual, technical and
          organizational measures are in place, such as the General Contractual
          Clauses approved by the European Commission.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        9. Connections
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          We may display third-party adverts and other content that contains
          links to third-party sites. We cannot, however, control or be
          responsible for the privacy practices and content of third parties. In
          case you click on a third-party advert or link, please note that you
          are leaving the Sioslife Services and any personal data you provide
          will not be covered by this Policy. We recommend that you read their
          privacy policies to understand how these third parties collect and
          process your personal data.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        10. Keeping your personal data secure
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          We are committed to protecting the personal data of our users. In this
          regard, we have implemented appropriate technical and organizational
          measures to assist in protecting the security of such personal data;
          however, please note that no system is completely secure. We have put
          in place various policies, including pseudonymisation, encryption,
          access and retention policies to protect against unauthorized access
          and unnecessary retention of personal data on our systems.
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          Your password protects your user account, so we encourage you to use a
          strong password that is unique to your Sioslife account, never sharing
          it with anyone, limiting access to your devices or browsers, as well
          as properly shutting down the Sioslife Services after each use.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        11. Minors
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          The Sioslife Services are not specially targeted to children under the
          age of 13. The Sioslife Services are also not offered to children
          whose age makes it unlawful to process their personal data, or
          requires parental consent for the processing of personal data under
          the GDPR or other local law.
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          We do not knowingly collect personal data from children under the age
          of 13 or under the applicable age limit (the &quot;Age Limit&quot;).
          If your age is below the Age Limit, please do not provide us with any
          personal data.
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          If you are a parent of a minor child and you know that your child has
          provided personal data to Sioslife, please contact us via the
          &quot;Contact Us&quot; form available on our website, where you can
          exercise your rights as detailed above in this Policy.
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          In the event that we become aware that we are collecting personal data
          from a child under the age of 13, we will take reasonable steps to
          delete the personal data concerned, which may lead us to delete their
          Sioslife account.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        12. Changes to this Policy
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          We may make changes to this Policy from time to time.
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          When we make material changes to this Policy, we will provide you with
          notice as appropriate under the circumstances, for example, by
          displaying a conspicuous notice on the Sioslife Services or by sending
          an email and/or a notification to your device. We will notify you in
          advance.
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          Please ensure that you read any such notice carefully.
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          For additional information about this Policy and how Sioslife uses
          your personal data, please contact us through any available channel.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        13. How can you contact us?
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          Thank you for reading our Policy. If you have any questions regarding
          this Policy, please contact us via e-mail at{" "}
          <a href={launcher ? "#" : "mailto:compliance@sioslife.com"}>
            compliance@sioslife.com
          </a>{" "}
          or the form on our website -{" "}
          <a href={launcher ? "#" : "https://www.sioslife.com"}>
            https://www.sioslife.com
          </a>
          . You can also send a letter to the following address:
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          <a
            href={launcher ? "#" : "https://g.page/sioslife?share"}
            rel="noreferrer"
            target={launcher ? "" : "_blank"}
          >
            Rua Eng. Nuno Alvares Pereira, Nº 33 Gualtar 4710-915, Braga
            (Portugal)
          </a>
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          Sioslife is a registered trademark and managed by the entity Sioslife
          S.A.. Sioslife S.A. is the entity responsible for the processing of
          personal data processed under this Policy.
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">We hope you enjoy our services!</Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">© Sioslife / Sioslife S.A.</Typography>
      </div>
    </LegalDocument>
  );
}

PrivacyPolicy.propTypes = {
  launcher: PropTypes.bool,
};

PrivacyPolicy.defaultProps = {
  launcher: false,
};

export default PrivacyPolicy;
